import { Autoplay, Keyboard, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Proj1Cover from "../../assets/projects/1/1-cover.png";
import Proj2Cover from "../../assets/projects/2/2-cover.png";
import Proj4Cover from "../../assets/projects/4/4-cover.png";
import Proj5Cover from "../../assets/projects/5/5-cover.png";
import Proj6Cover from "../../assets/projects/6/6-cover.png";
import Proj7Cover from "../../assets/projects/7/7-cover.png";
import Proj8Cover from "../../assets/projects/8/8-cover.png";
import Proj9Cover from "../../assets/projects/9/9-cover.png";
import Proj1Presentation from "../../assets/projects/1/1-presentation.pdf";
import Proj2Presentation from "../../assets/projects/2/2-presentation.pdf";
import Proj4Presentation from "../../assets/projects/4/4-presentation.pdf";
import Proj5Presentation from "../../assets/projects/5/5-presentation.png";
import Proj6Presentation from "../../assets/projects/6/6-presentation.pdf";
import Proj8Presentation from "../../assets/projects/8/8-presentation.pdf";
import Proj9Presentation from "../../assets/projects/9/9-presentation.pdf";
import "swiper/css";
import "swiper/css/navigation";

const projects = [
  {
    name: "Crystalline Rose",
    img: Proj1Cover,
    presentation: Proj1Presentation,
  },
  { name: "Marco Indigo", img: Proj2Cover, presentation: Proj2Presentation },
  {
    name: "Sounder",
    img: Proj8Cover,
    presentation: Proj8Presentation,
    objectPosition: "top",
  },
  {
    name: "Sounder Email",
    img: Proj9Cover,
    presentation: Proj9Presentation,
  },
  {
    name: "WeStream",
    img: Proj5Cover,
    presentation: Proj5Presentation,
    figma:
      "https://www.figma.com/proto/JRfqEgIhu1MU2NOMpC9c25/WeStream?type=design&node-id=104-52&t=B8tZDyoN0P8Jv6Zs-1&scaling=min-zoom&page-id=0%3A1&mode=design",
  },
  {
    name: "Wolfox",
    img: Proj4Cover,
    presentation: Proj4Presentation,
    figma:
      "https://www.figma.com/file/q7CPRQW8j0AReuErrfH3Rd/Wolfox?type=design&node-id=0-1&mode=design&t=ovLCyTIM7lWLDxV6-0",
  },
  {
    name: "Arpal Puglia",
    img: Proj6Cover,
    presentation: Proj6Presentation,
  },
  {
    name: "Interreg",
    img: Proj7Cover,
    figma:
      "https://www.figma.com/file/Pdhqk4Q4zZKI4XLgCQtBxv/Interreg?type=design&node-id=0%3A1&mode=design&t=KXsfeoJFt8og1CtF-1",
  },
];

export default function ProjectsList() {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      loop
      navigation
      keyboard
      pagination
      onSwiper={(swiper) => console.log(swiper)}
      modules={[Autoplay, Navigation, Pagination, Keyboard]}
    >
      {projects.map((proj) => (
        <SwiperSlide key={proj.name}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ width: 1280, maxWidth: "100%" }}>
              <a
                href={proj.presentation || proj.img}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={proj.img}
                  alt={proj.name}
                  style={{
                    width: "100%",
                    height: 720,
                    maxHeight: "50vh",
                    objectFit: "cover",
                    borderRadius: 20,
                    overflow: "hidden",
                    objectPosition: proj.objectPosition || "center",
                  }}
                />
              </a>
              {(!!proj.presentation || !!proj.figma) && (
                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 20,
                  }}
                >
                  {!!proj.presentation?.endsWith(".pdf") && (
                    <a
                      href={proj.presentation}
                      target="_blank"
                      style={{
                        padding: "1rem 2rem",
                        backgroundColor: "var(--pink)",
                        borderRadius: 9999,
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      rel="noopener noreferrer"
                    >
                      <b>PDF</b>
                    </a>
                  )}
                  {!!proj.figma && (
                    <a
                      href={proj.figma}
                      target="_blank"
                      style={{
                        padding: "1rem 2rem",
                        backgroundColor: "var(--pink)",
                        borderRadius: 9999,
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      rel="noopener noreferrer"
                    >
                      <b>Figma</b>
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
