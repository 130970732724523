import ScrollAnimation from "react-animate-on-scroll";

import RewardIcon from "../../assets/reward.png";

import { Container } from "./styles";

const certificates = [
  {
    name: "Graphic Design Industry Training",
    from: "Brainnest",
    when: "AUG 2022 - SEP 2022",
  },
  {
    name: "Graphic Principles for Brand Identity",
    from: "BIT Formazione",
    when: "JUL 2022 - SEP 2022",
  },
  {
    name: "Adobe Illustrator Certificate",
    from: "Adobe",
    when: "In progress...",
  },
] as const;

export default function Certificates() {
  return (
    <Container id="certificates">
      <h2 style={{ marginBottom: "5rem" }}>Certificates</h2>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ maxWidth: "100%" }}>
          {certificates.map((cert) => (
            <div key={cert.name} style={{ margin: "30px 0px" }}>
              <ScrollAnimation
                animateIn="flipInX"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img src={RewardIcon} width={100} alt="Folder" />
                <div>
                  <h3 style={{ fontSize: "2.5rem", letterSpacing: 1 }}>
                    {cert.name}
                  </h3>
                  <p style={{ fontSize: "2rem", margin: "0.5rem 0" }}>
                    <i>{cert.from}</i>
                  </p>
                  <p style={{ opacity: 0.9, letterSpacing: 1.5 }}>
                    {cert.when}
                  </p>
                </div>
              </ScrollAnimation>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}
